import React, { memo } from 'react';
import type { DeepDiveProviderProps } from '../../common/types';
import { DeepDiveCardProvider as DeepDiveCardProviderBase } from './main';

const isEqual = (prev: DeepDiveProviderProps, next: DeepDiveProviderProps) =>
	prev.recentProjects === next.recentProjects &&
	prev.recent2QueuesByRecentProjects === next.recent2QueuesByRecentProjects &&
	prev.isLoading === next.isLoading;
export const DeepDiveCardProvider = memo<DeepDiveProviderProps>(
	(props: DeepDiveProviderProps) => <DeepDiveCardProviderBase {...props} />,
	isEqual,
);
