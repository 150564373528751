import { Component } from 'react';
import { browserMetrics } from '@atlassian/browser-metrics';
import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import { stopLowPriorityEventDelay } from '@atlassian/jira-event-delay-controller';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	resetTTIObserver,
	getTTIValue,
} from '@atlassian/jira-software-measure-tti/src/services/measure-tti';
import { PRODUCT_START_MARK } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import {
	setInitialPageLoadTimingFromPerformanceMarks,
	stopInitialPageLoadTimingFromPerformanceMarkStart,
} from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { isAutomaticExposureCollectionEnabled } from '@atlassian/jira-track-all-changes-sampling';
import UFOInteractionContext, {
	type UFOInteractionContextType,
} from '@atlassian/react-ufo/interaction-context';
import { TTI_RAF_MARK } from '../../constants';
import type { BM3Metric, CustomData } from '../types';

const emitMetrics = (metric: BM3Metric, customData: CustomData, stopTime?: number) => {
	!ff('bm3.emit-on-raf.top-experiences') &&
		stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);
	setInitialPageLoadTimingFromPerformanceMarks(
		'batch-js-heritage.eval',
		'jira.heritage_batch_file_eval:start',
		'jira.heritage_batch_file_eval:end',
	);

	if (stopTime !== undefined) {
		if (!ff('bm3.wait-for-long-task')) {
			metric.mark(TTI_RAF_MARK);

			setMark(TTI_RAF_MARK);
			setMeasure('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', TTI_RAF_MARK);
		}
		metric.stop({
			customData,
			stopTime,
		});
	} else {
		metric.stop({ customData });
	}

	if (isAutomaticExposureCollectionEnabled()) {
		stopLowPriorityEventDelay();
	}
};

// eslint-disable-next-line jira/react/no-class-components
export class StopBrowserMetrics3 extends Component<{
	metric?: BM3Metric;
	customData: CustomData;
	emitOnAnimationFrame?: Boolean;
	waitForLongTaskIdleTTI?: boolean;
}> {
	componentDidMount() {
		const { metric, customData, emitOnAnimationFrame } = this.props;

		if (!metric) {
			return;
		}

		const start = ff('bm3.wait-for-long-task')
			? browserMetrics.getPageLoadMetric().getData()?.start || 0
			: 0;

		setMark('BROWSER_METRICS_TTI_START', { startTime: start });
		setMark('BROWSER_METRICS_TTI_END');
		setMeasure('BROWSER_METRICS_TTI', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_END');

		const bm3Data = metric.getData();
		if (this.props.waitForLongTaskIdleTTI) {
			const stopTime = performance.now();
			ff('bm3.emit-on-raf.top-experiences') &&
				stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);

			getTTIValue().then((tti) => {
				resetTTIObserver();
				setMark('BROWSER_METRICS_LONG_TASK_TTI_END', { startTime: tti });
				setMeasure(
					'BROWSER_METRICS_LONG_TASK_TTI',
					'BROWSER_METRICS_TTI_START',
					'BROWSER_METRICS_LONG_TASK_TTI_END',
				);
				emitMetrics(metric, customData, stopTime);
			});
			if (bm3Data) {
				this.context?.addApdex({
					key: bm3Data.key,
					startTime: bm3Data.start,
					stopTime,
				});
			}
		} else if (emitOnAnimationFrame) {
			const stopTime = performance.now();
			ff('bm3.emit-on-raf.top-experiences') &&
				stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);

			if (bm3Data) {
				this.context?.addApdex({
					key: bm3Data.key,
					startTime: bm3Data.start,
					stopTime,
				});
			}

			if (typeof queueMicrotask !== 'undefined') {
				queueMicrotask(() => {
					setMark('BROWSER_METRICS_TTI_TASK_END');
					setMeasure(
						'BROWSER_METRICS_TTI_TASK',
						'BROWSER_METRICS_TTI_START',
						'BROWSER_METRICS_TTI_TASK_END',
					);
				});
			}

			requestAnimationFrame(() => {
				if (ff('bm3.wait-for-long-task')) {
					metric.mark(TTI_RAF_MARK);
					setMark(TTI_RAF_MARK);
					setMeasure('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', TTI_RAF_MARK);
				}
				if (ff('bm3.wait-for-long-task')) {
					setTimeout(() => {
						emitMetrics(metric, customData, stopTime);
					}, 500);
				} else {
					emitMetrics(metric, customData, stopTime);
				}
			});
		} else {
			if (bm3Data) {
				this.context?.addApdex({
					key: bm3Data.key,
					startTime: bm3Data.start,
					stopTime: performance.now(),
				});
			}

			ff('bm3.emit-on-raf.top-experiences') &&
				stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);
			emitMetrics(metric, customData);
		}
	}

	static contextType = UFOInteractionContext;

	// @ts-expect-error - TS2564: Property 'context' has no initializer and is not definitely assigned in the constructor.
	context: UFOInteractionContextType;

	render() {
		return null;
	}
}
