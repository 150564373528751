import { useEffect, useRef } from 'react';
import { HOME_PAGE_DEFAULT_OPEN_GIC } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';

const OPEN_GLOBAL_ISSUE_CREATE = 'createissue';

export const IssueCreateHomePageTrigger = () => {
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const createIssueRef = useRef(true);
	const [openGlobalIssueCreate, _setopenGlobalIssueCreate] =
		useQueryParam(OPEN_GLOBAL_ISSUE_CREATE);

	useEffect(() => {
		if (createIssueRef.current && openGlobalIssueCreate === 'true') {
			openIssueCreateModal({
				triggerPointKey: HOME_PAGE_DEFAULT_OPEN_GIC,
			});
		}
		createIssueRef.current = false;
	}, [openIssueCreateModal, openGlobalIssueCreate]);
	return null;
};

export default IssueCreateHomePageTrigger;
