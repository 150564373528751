import { Component } from 'react';
import UFOInteractionContext, {
	type UFOInteractionContextType,
} from '@atlassian/react-ufo/interaction-context';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import type { CustomData } from '../types';

// eslint-disable-next-line jira/react/no-class-components
export class StopUfo extends Component<{
	appName?: string;
	customData: CustomData;
}> {
	componentDidMount() {
		const { customData, appName } = this.props;
		GlobalPageLoadExperience.success({ force: true, metadata: customData });

		const name = appName || GlobalPageLoadExperience.loadingPageLoadId;

		if (name) {
			this.context?.addApdex({
				key: name,
				stopTime: performance.now(),
			});
		}
	}

	static contextType = UFOInteractionContext;

	// @ts-expect-error - TS2564: Property 'context' has no initializer and is not definitely assigned in the constructor.
	context: UFOInteractionContextType;

	render() {
		return null;
	}
}
