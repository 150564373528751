import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { emitSignal } from '@atlassian/jira-common-integration-tests/src/signal/emit';
import {
	type ItsmPractice,
	INCIDENTS,
	CHANGES,
	SERVICE_REQUEST,
	PROBLEMS,
	POST_INCIDENT_REVIEWS,
	NO_CATEGORY,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Query } from '@atlassian/react-resource-router';
import { createPushStateNavigator, type PushStateNavigator } from '../push-state-navigator';

const LOCATION = 'servicedesk.common.navigation.queues';

export type QueueCategory = ItsmPractice | undefined;

let navigatorPromise: Promise<PushStateNavigator>;
const getPushStateNavigator = () => {
	if (!navigatorPromise) {
		navigatorPromise = createPushStateNavigator();
	}
	return navigatorPromise;
};

export const QUEUE_ROUTES = {
	[INCIDENTS]: 'section/incidents',
	[CHANGES]: 'section/changes',
	[SERVICE_REQUEST]: 'section/service-requests',
	[PROBLEMS]: 'section/problems',
	[POST_INCIDENT_REVIEWS]: 'section/post-incident-reviews',
	[NO_CATEGORY]: 'queues',
} as const;

const fromCategory = (category: QueueCategory): string => QUEUE_ROUTES[category || NO_CATEGORY];

/**
 * Navigate to a given location.
 *
 * @param projectKey is the projectKey of the current project
 * @param fragment URL relative to the queues base URL.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const navigate = (projectKey: string, fragment: string): Observable<any> => {
	emitSignal(LOCATION, { projectKey, fragment });
	return Observable.fromPromise(getPushStateNavigator())
		.mergeMap((pushStateNavigator) => {
			pushStateNavigator.navigateToFragment(fragment);
			return Observable.empty<never>();
		})
		.catch(() => Observable.empty<never>());
};

type GetQueueUrlArgs = {
	projectKey: ProjectKey;
	category?: QueueCategory;
	queueId: number | undefined | null;
	queryParams?: Query;
};

export const getQueueFragment = (queueId?: number | null, category?: QueueCategory): string => {
	const categoryString = fromCategory(category);
	return queueId != null ? `${categoryString}/custom/${queueId}` : `${categoryString}`;
};

export const getEditQueueFragment = (queueId: number, category?: QueueCategory): string =>
	`${getQueueFragment(queueId, category)}/edit`;

export const getCloneQueueFragment = (queueId: number, category?: QueueCategory): string => {
	const categoryString = fromCategory(category);
	return queueId != null
		? `${categoryString}/custom/new?parentId=${queueId}`
		: `${categoryString}/custom/new`;
};

export const getQueueIssueFragment = (
	queueId: number,
	issueKey: string,
	category?: QueueCategory,
): string => `${getQueueFragment(queueId, category)}/${issueKey}`;

export const getIssueFragment = (issueKey: string): string =>
	`${getQueueFragment()}/issue/${issueKey}`;

export const getQueueUrl = ({
	projectKey,
	category,
	queueId,
	queryParams,
}: GetQueueUrlArgs): string => {
	const urlSearchParams = new URLSearchParams();

	if (queryParams) {
		Object.entries(queryParams).forEach(([key, value]) => {
			urlSearchParams.set(key, value);
		});
	}

	const formattedQueryParams = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';

	return `/jira/servicedesk/projects/${projectKey}/${getQueueFragment(
		queueId,
		category,
	)}${formattedQueryParams}`;
};

export const getEditQueueUrlFromManageQueue = (
	queueId: number,
	projectKey: string,
	category?: QueueCategory,
): string => `/jira/servicedesk/projects/${projectKey}/${getEditQueueFragment(queueId, category)}`;
