import React, { Component } from 'react';
import debounce from 'lodash/debounce';
// eslint-disable-next-line @atlaskit/design-system/no-unsupported-drag-and-drop-libraries
import type { DropResult } from 'react-beautiful-dnd';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { favoritesChangedPessimistically } from '@atlassian/jira-favourite-change-provider/src/common/utils/index.tsx';
import type { FavoriteChangeContextType } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { FavoriteChangeConsumer } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import SpaStateTransitionStateSubscriber from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import type { TransitionState } from '@atlassian/jira-spa-state-controller/src/types.tsx';
import {
	MIN_WAIT_BEFORE_REFRESH_MS,
	INIT_RELOAD_BUFFER_MS,
	RELOAD_FAVOURITES_DEBOUNCE_MS,
} from '../../common/constants/refresh-times';
import type {
	ItemProviderState,
	ItemProviderProps,
	ItemProviderData,
} from '../../common/types/item-provider';
import { isItemProviderDataEqual } from '../../common/utils';

export const ItemProviderInitialData = { sections: [], total: 0 } as const;

export type Props = ItemProviderProps & {
	favoriteChangeContext: FavoriteChangeContextType;
	spaStateTransitionState?: TransitionState;
};

// eslint-disable-next-line jira/react/no-class-components
export class ItemProvider extends Component<Props, ItemProviderState> {
	// eslint-disable-next-line react/sort-comp
	didReceivePrefetchData = (): boolean => {
		const { prefetchResult, providerKey } = this.props;
		return (
			!prefetchResult.loading &&
			!!prefetchResult.data &&
			!!prefetchResult.data.tabs &&
			!!prefetchResult.data.tabs[providerKey] &&
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
			!!prefetchResult.data.tabs[providerKey].data
		);
	};

	isResultMissingInPrefetch = (): boolean => {
		const { prefetchResult, providerKey } = this.props;
		return (
			!prefetchResult.loading &&
			(!prefetchResult.data ||
				!prefetchResult.data.tabs ||
				!prefetchResult.data.tabs[providerKey] ||
				// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				!prefetchResult.data.tabs[providerKey].data)
		);
	};

	parsePrefetchData = (): ItemProviderData => {
		const { prefetchResult, providerKey } = this.props;
		if (
			!prefetchResult.loading &&
			prefetchResult.data &&
			prefetchResult.data.tabs &&
			prefetchResult.data.tabs[providerKey] &&
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
			prefetchResult.data.tabs[providerKey].data
		) {
			// @ts-expect-error - TS2322 - Type 'ItemProviderData | undefined' is not assignable to type 'ItemProviderData'. | TS2532 - Object is possibly 'undefined'.
			return prefetchResult.data.tabs[providerKey].data;
		}
		// @ts-expect-error - TS2322 - Type '{ readonly sections: readonly []; readonly total: 0; }' is not assignable to type 'ItemProviderData'.
		return ItemProviderInitialData;
	};

	state = {
		data: this.parsePrefetchData(),
		loading: !this.didReceivePrefetchData(),
		reloading: false,
		initialised: this.didReceivePrefetchData(),
		error: undefined,
		fetchTime: new Date().getTime() + MIN_WAIT_BEFORE_REFRESH_MS + INIT_RELOAD_BUFFER_MS,
		updateAvailable: false,
	};

	componentDidMount() {
		if (this.props.spaStateTransitionState && !this.props.spaStateTransitionState.isInitialRender) {
			this.fetchData();
		}

		this.canSetState = true;
		if (this.props.prefetchResult.error || this.isResultMissingInPrefetch()) {
			this.fetchData();
		} else if (this.didReceivePrefetchData()) {
			this.props.onTotalChange(this.props.id, this.state.data.total);
		}

		if (window) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('visibilitychange', this.reload);
		}
	}

	isUpdateAvailable = () => {
		const { data } = this.state;
		const latest = this.parsePrefetchData();
		return this.state.updateAvailable === true || !isItemProviderDataEqual(data, latest);
	};

	shouldAutoUpdate = () => {
		const { data } = this.state;
		return this.props.autoUpdate === true || !data || !data.sections || data.sections.length === 0;
	};

	updateToLatest = () => {
		const data = this.parsePrefetchData();
		this.updateData(data);
	};

	updateData = (data: ItemProviderData) => {
		const { id, onTotalChange } = this.props;
		if (!this.state.data || this.state.data.total !== data.total) {
			onTotalChange && onTotalChange(id, data.total);
		}
		this.setState({ data, updateAvailable: false });
	};

	async componentDidUpdate(prevProps: Props) {
		if (!prevProps.prefetchResult.error && this.props.prefetchResult.error) {
			this.fetchData();
			return;
		}

		if (!prevProps.prefetchResult.data && this.props.prefetchResult.data) {
			this.addDataToState(this.parsePrefetchData());
			return;
		}

		if ((this.shouldAutoUpdate() || !this.props.selected) && this.isUpdateAvailable()) {
			this.updateToLatest();
		}

		if (
			!this.shouldAutoUpdate() &&
			this.state.updateAvailable !== true &&
			this.isUpdateAvailable()
		) {
			this.setState({ updateAvailable: true });
		}

		if (this.state.initialised === false) {
			return;
		}
		if (this.state.loading === true) {
			return;
		}

		if (
			(!prevProps.selected && this.props.selected) ||
			favoritesChangedPessimistically(
				prevProps.favoriteChangeContext,
				this.props.favoriteChangeContext,
			)
		) {
			// attempt to reload the data
			this.reload();
		}
	}

	componentWillUnmount() {
		this.canSetState = false;
		if (window) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('visibilitychange', this.reload);
		}
	}

	canSetState = false;

	fetching = false;

	addDataToState = (data: ItemProviderData) => {
		if (!this.canSetState) {
			return;
		}

		this.setState((prevState: ItemProviderState, props: Props) => {
			if (prevState.initialised === false) {
				props.onTotalChange(props.providerKey, data.total);
			} else if (prevState.data.total !== data.total) {
				props.onTotalChange(props.providerKey, data.total);
			}
			return {
				loading: false,
				initialised: true,
				data,
				fetchTime: new Date().getTime() + MIN_WAIT_BEFORE_REFRESH_MS,
				error: undefined,
			};
		});

		this.fetching = false;
	};

	fetchData = async () => {
		if (this.fetching === true) {
			return;
		}

		try {
			this.fetching = true;

			const { prefetchResult, providerKey } = this.props;

			prefetchResult.data?.tabs[providerKey]?.update?.();

			this.setState({
				loading: false,
				initialised: true,
				fetchTime: new Date().getTime() + MIN_WAIT_BEFORE_REFRESH_MS,
				error: undefined,
			});

			this.fetching = false;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			if (this.canSetState) {
				// @ts-expect-error - TS2345 - Argument of type '(prevState: Readonly<ItemProviderState>) => { loading: false; error: any; data: ItemProviderData | { readonly sections: readonly []; readonly total: 0; }; reloading: boolean; initialised: boolean; fetchTime: number; updateAvailable?: boolean | undefined; }' is not assignable to parameter of type 'ItemProviderState | ((prevState: Readonly<ItemProviderState>, props: Readonly<Props>) => ItemProviderState | Pick<...> | null) | Pick<...> | null'.
				this.setState((prevState) => ({
					...prevState,
					loading: false,
					error: e,
					data: prevState.data.total ? prevState.data : ItemProviderInitialData,
				}));
				fireErrorAnalytics({
					error: e,
					meta: { id: 'itemProvider', packageName: 'jiraHome' },
				});
			}
			this.fetching = false;
		}
	};

	reorderData = (result: DropResult) => {
		this.onReorderData(result);
	};

	onReorderData = async (result: DropResult) => {
		if (!this.props.onReorderItemsNew) return;
		const { prefetchResult, providerKey } = this.props;
		const { sections } = this.state.data;

		const update = prefetchResult.data?.tabs[providerKey]?.update;

		const resolve = this.props.onReorderItemsNew(result, sections, update);

		// send backend request
		const itemsReodered = !!resolve && (await resolve.save());

		// call the reload to update view if the server returns error.
		if (!itemsReodered) {
			this.reload();
		}
	};

	reload = debounce(async () => {
		// Don't attempt reload if the network is down, it causes errors.
		if (
			typeof window !== 'undefined' &&
			window.navigator &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			typeof window.navigator.onLine !== 'undefined' &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.navigator.onLine === false
		) {
			return;
		}

		if (
			!this.state.loading &&
			!this.state.reloading &&
			new Date().getTime() > this.state.fetchTime &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.visibilityState === 'visible'
		) {
			this.setState({ reloading: true });
			await this.fetchData();
			this.setState({ reloading: false });
		}
	}, RELOAD_FAVOURITES_DEBOUNCE_MS);

	render() {
		return this.props.children({
			data: this.state.data,
			loading: this.state.loading,
			reloading: this.state.reloading,
			error: this.state.error,
			reorder: this.reorderData,
			updateAvailable: this.state.updateAvailable,
			update: this.updateToLatest,
		});
	}
}

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: ItemProviderProps) => (
	<FavoriteChangeConsumer>
		{(favoriteChangeContext) => (
			<SpaStateTransitionStateSubscriber>
				{(spaStateTransitionState) => (
					<ItemProvider
						{...props}
						favoriteChangeContext={favoriteChangeContext}
						spaStateTransitionState={spaStateTransitionState}
					/>
				)}
			</SpaStateTransitionStateSubscriber>
		)}
	</FavoriteChangeConsumer>
);
