/* eslint-disable jira/react/handler-naming */
import React, { Component, type ReactElement } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const defaultIconSize = gridSize * 2;

export type Props = {
	url: string | null | undefined;
	title: string | null | undefined;
	alt: string | null | undefined;
	width: number;
	height: number;
	fallback: () => ReactElement | null | undefined;
};

type State = {
	isBroken: boolean;
};

/* Represents an asynchronous icon loader that handles loading of icons from URLs with error handling.
 * It displays the icon fetched from the provided URL. In case of an error or if the URL is undefined,
 * a fallback UI component or placeholder is displayed.
 * This component accepts various props like URL, title, alt text, dimensions, and a fallback component
 * to customize the behavior and presentation of the loaded or placeholder icon.
 * The component also includes functionality to prevent the icon from being draggable.
 */
// eslint-disable-next-line jira/react/no-class-components
export default class AsyncIcon extends Component<Props, State> {
	static defaultProps = {
		url: undefined,
		title: undefined,
		alt: undefined,
		width: defaultIconSize,
		height: defaultIconSize,
		fallback: undefined,
	};

	state = { isBroken: false };

	onError = () => {
		this.setState({ isBroken: true });
	};

	convertSize = (size: number) => (size ? `${size}px` : '0');

	render() {
		const { title, url, alt, fallback } = this.props;
		const width = this.convertSize(this.props.width);
		const height = this.convertSize(this.props.height);

		if (this.state.isBroken && fallback) {
			return fallback();
		}
		if (url) {
			return (
				<Image
					src={url}
					width={width}
					height={height}
					title={title ?? undefined}
					alt={alt ?? ''}
					onError={this.onError}
					/**
					 * Disabling dragging so drags happen on the parent instead of this icon.
					 */
					draggable={false}
				/>
			);
		}

		const isNew = fg('jfp-1020-use-compiled');

		return isNew ? (
			<Box
				xcss={placeholderStyles}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{ height, width }}
				backgroundColor="color.background.input.hovered"
				testId="common-components-async-icon.placeholder"
			/>
		) : (
			<ImagePlaceholder
				width={width}
				height={height}
				data-testid="common-components-async-icon.placeholder"
			/>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImagePlaceholder = styled.span<{ height: string | number; width: string | number }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: props.height,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: props.width,
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: colors.N30,
		borderRadius: '3px',
		display: 'flex',
		flex: 'none',
	}),
);
const placeholderStyles = xcss({
	borderRadius: '3px',
	display: 'flex',
	flex: 'none',
});

// Firefox displays the alt text when the image can't load, the narrow provided width
// means this alt text leaks into the summary vertically if overflow is allowed
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled2.img({
	overflow: 'hidden',
	flex: 'none',
});
