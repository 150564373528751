import React, { Component } from 'react';
import { DocumentTitleHelper, DocumentTitleSubscriber } from './main';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DocumentTitleContainer, useDocumentTitleActions } from './main';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ripTitleTemplateFromMeta } from './utils';

type DocumentTitleProps = {
	title: string;
	TitleSubscriber: typeof DocumentTitleSubscriber;
};

// eslint-disable-next-line jira/react/no-class-components
export class DocumentTitle extends Component<DocumentTitleProps> {
	static defaultProps = {
		TitleSubscriber: DocumentTitleSubscriber,
	};

	shouldComponentUpdate(nextProps: DocumentTitleProps) {
		return nextProps.title !== this.props.title;
	}

	render() {
		const { title, TitleSubscriber } = this.props;
		return (
			<TitleSubscriber>
				{/* @ts-expect-error - TS2339 - Property 'contextArray' does not exist on type 'undefined'. */}
				{({ contextArray }, { setTitle, addDocumentTitle, removeDocumentTitle }) => (
					<DocumentTitleHelper
						title={title}
						setTitle={setTitle}
						addDocumentTitle={addDocumentTitle}
						contextArray={contextArray}
						removeDocumentTitle={removeDocumentTitle}
						TitleSubscriber={TitleSubscriber}
					/>
				)}
			</TitleSubscriber>
		);
	}
}
