import React, { Component } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import UFOCustomData from '@atlassian/react-ufo/custom-data';
import { UFOBM3TimingsToUFO } from '@atlassian/react-ufo/custom-timings';
import { getInteractionId } from '@atlassian/react-ufo/interaction-id-context';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import { StopBrowserMetrics3 } from './stop-bm3';
import { StopUfo } from './stop-ufo';
import type { BM3Metric, BM3Config, CustomData } from './types';
import { addBM3SSRDoneAsFMPToInteraction } from './utils/add-bm3-config-to-interaction';
import { addUFOSSRDoneAsFMPToInteraction } from './utils/add-ufo-config-to-interaction';
import { trackBM3FeatureFlagsAccessed } from './utils/track-bm3-feature-flags-accessed';

export type Props = {
	extra: CustomData;
	appName?: string;
	metricKey?: string;
	metric?: BM3Metric;
	emitOnAnimationFrame?: Boolean;
	/**
	 * If set to true, will wait for a 5s window without long-tasks before
	 * submitting TTI. This requires calling `startTTIObserver` as early as
	 * possible during the page-load. To be used on synthetics/labs only and
	 * never in production.
	 *
	 * See @atlassian/jira-software-measure-tti
	 */
	waitForLongTaskIdleTTI?: boolean;
};

export const checkAndEmitApdexSignal = (appName?: string, metricKey?: string) => {
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line jira/import/webpack-magic-comment-entries
		import('@atlassian/jira-common-integration-tests/src/signal/emit').then(({ emitSignal }) =>
			emitSignal('apdex.submited', {
				appName,
				metricKey,
			}),
		);
	}
};

// Props, defaultProps and checkAndEmitApdexSignal are consumed in ../apdex-actions
// Changes here should be duplicated there
// eslint-disable-next-line jira/react/no-class-components
export default class SubmitApdexMark extends Component<Props> {
	static defaultProps = {
		isExpectedToHaveSsr: false,
		withMarks: [],
		extra: {},
		emitOnAnimationFrame: false,
	};

	render() {
		const { appName, metricKey, extra, metric, emitOnAnimationFrame, waitForLongTaskIdleTTI } =
			this.props;

		checkAndEmitApdexSignal(appName, metricKey);

		const isUFODefined = ff('uip.ufo') && GlobalPageLoadExperience.loadingPageLoadId !== 'UNKNOWN';

		if (isUFODefined) {
			addUFOSSRDoneAsFMPToInteraction();
		}
		if (metric) {
			// https://product-fabric.atlassian.net/browse/UFO-323
			addBM3SSRDoneAsFMPToInteraction(metric);
			trackBM3FeatureFlagsAccessed(metric);
		}

		return (
			<>
				<UFOCustomData data={extra} />
				<UFOBM3TimingsToUFO
					marks={metric?.getData()?.marks}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					timings={(metric?.getData()?.config as BM3Config)?.timings}
				/>
				{isUFODefined ? (
					<StopUfo appName={appName} customData={extra} key={getInteractionId().current} />
				) : (
					metric && (
						<StopBrowserMetrics3
							metric={metric}
							customData={extra}
							emitOnAnimationFrame={emitOnAnimationFrame}
							waitForLongTaskIdleTTI={waitForLongTaskIdleTTI}
						/>
					)
				)}
			</>
		);
	}
}

export const baseDefaultProps = SubmitApdexMark.defaultProps;
